<template>
  <utilisateur-form />
</template>

<script>
import UtilisateurForm from "@/components/UtilisateurForm";
export default {
  name: "UtilisateursAdd",
  components: {UtilisateurForm}
}
</script>

<style scoped>

</style>