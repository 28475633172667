

<template>
  <div>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <b-form-group
                label="Nom"
                label-for="nom"

            >
              <b-form-input
                  :class="{'is-invalid' : form_errors.nom}"
                  v-model="utilisateur.nom"
                  id="nom"
                  autocomplete="new-nom"
                  placeholder="Nom"
              />
              <small v-if="form_errors.nom" class="text-danger">{{ form_errors.nom[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Prénom"
                label-for="prenom"
            >
              <b-form-input
                  :class="{'is-invalid' : form_errors.prenom}"
                  v-model="utilisateur.prenom"
                  id="prenom"
                  placeholder="Prenom"
              />
              <small v-if="form_errors.prenom" class="text-danger">{{ form_errors.prenom[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Email"
                label-for="nom"

            >
              <b-form-input
                  :class="{'is-invalid' : form_errors.email}"
                  v-model="utilisateur.email"
                  id="email"
                  autocomplete="new-password"
                  placeholder="Email"
              />
              <small v-if="form_errors.email" class="text-danger">{{ form_errors.email[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Téléphone"
                label-for="telephone"

            >
              <b-form-input
                  :class="{'is-invalid' : form_errors.telephone}"
                  v-model="utilisateur.telephone"
                  id="telephone"
                  placeholder="Téléphone"
              />
              <small v-if="form_errors.telephone" class="text-danger">{{ form_errors.telephone[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Concession"
                label-for="concession"
                :class="{'is-invalid' : form_errors.concession}"
            >
              <v-select id="concession" v-model="utilisateur.concession"
                        :options="$store.state.initialConfigModule.initialState.concessions"

                        label="libelle"/>
              <small v-if="form_errors.concession" class="text-danger">{{ form_errors.concession[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Site"
                label-for="site"
                :class="{'is-invalid' : form_errors.site}"
            >
              <v-select id="concession" v-model="utilisateur.site"
                        :options="$store.state.initialConfigModule.initialState.sites"

                        label="libelle"/>
              <small v-if="form_errors.site" class="text-danger">{{ form_errors.site[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Role"
                label-for="role"
                :class="{'is-invalid' : form_errors.role}"
            >
              <v-select id="role" v-model="utilisateur.role"
                        :options="$store.state.initialConfigModule.initialState.roles"

                        label="libelle"/>
              <small v-if="form_errors.role" class="text-danger">{{ form_errors.role[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Mot de passe"
                label-for="password"

            >
              <b-form-input
                  :class="{'is-invalid' : form_errors.password}"
                  v-model="utilisateur.password"
                  id="password"
                  placeholder="Mot de passe"
                  type="password"
              />
              <small v-if="form_errors.password" class="text-danger">{{ form_errors.password[0] }}</small>
            </b-form-group>
          </b-col>

        </b-row>

      </b-form>
    </b-card>
    
    <div class="text-right">
      <b-button
          type="submit"
          variant="primary"
          class="mr-1"
          @click="submit"
      >
        Enregistrer
      </b-button>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  name: 'UtilisateurForm',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    initialUtilisateur: {
      default: false,
    }
  },
  methods: {
    submit() {
      let _this = this;
      _this.form_errors = {};
      this.$store.dispatch('userModule/userStore', this.utilisateur).then(function (response) {
        _this.$router.push('/utilisateurs')
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          _this.form_errors = error.response.data.errors;
        }

      })
    }
  },
  created() {
    this.utilisateur = {...this.initialUtilisateur};
  },
  data() {
    return {
      utilisateur: {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        concession: {},
        telephone: '',
        role : {}
      },
      form_errors: {}
    }
  },
}
/*
<ul class="chosen-results"><li class="active-result result-selected" data-option-array-index="1" style="">MR</li><li class="active-result" data-option-array-index="2" style="">COMPANY</li><li class="active-result" data-option-array-index="3" style="">GARAGE</li><li class="active-result" data-option-array-index="4" style="">MESSRS</li><li class="active-result" data-option-array-index="5" style="">MME</li><li class="active-result" data-option-array-index="6" style="">MMES</li><li class="active-result" data-option-array-index="7" style="">MS</li></ul>
 */
</script>
